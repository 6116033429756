
/*TODO manuela: choiamare endpoint dashboard.get_matricolaBando e mostrare */
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, MSection, SupportoTecnico, Title } from "components/misc";
import { CourseHelper, courseService, learningService, networkErrorHelper } from "libs";
import { Icon } from "design-react-kit";
import { ROUTES } from "const";

class enrollmentResult extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: '',
        matricolaBando: '',
        evaluationBando: '',

    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, loading: false, loadingError: false, rCode });
                courseService.bandoEvaluationInfo(userCourse.pluginConfig['region'], course.instituteCompany, userCourse.pluginConfig['competitionClass'])
                    .then(({ data }) => {

                        const { evaluationBando } = data.payload;
                        this.setState({ evaluationBando });

                    })
                    .catch(error => {

                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }

                        this.setState({ loading: false, loadingError: true });
                    });


            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });

        courseService.matricolaBando(rCode)
            .then(({ data }) => {

                const { matricolaBando } = data.payload;
                this.setState({ matricolaBando });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });

    }

    render() {
        let title = '';
        const { loading, loadingError, rCode, course, userCourse, matricolaBando, evaluationBando } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (course.code.startsWith('IUL60') || course.code.startsWith('UM60')) {
            title = "Abilitazione all'insegnamento della classe di concorso " + course.title + "(Percorsi di formazione iniziale di 60 CFU di cui al D.P.C.M.4 agosto 2023 - ALL. 1) - " + userCourse.pluginConfig['region'].toUpperCase();
        } else {
            title = "Abilitazione all'insegnamento della classe di concorso " + course.title + " (Percorsi di formazione iniziale di 30 CFU di cui al D.P.C.M.4 agosto 2023 - ALL. 2) - " + userCourse.pluginConfig['region'].toUpperCase();
        }
        let university = '';
        if (CourseHelper.isIUL) {
            university = 'Università Telematica degli Studi IUL';
        } else {
            university = 'Università UniCamillus (Saint Camillus International University of Health Sciences)';
        }

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Consultazione gradutoria</Title>
            <MSection>
                <p>Gentile Candidata/o,</p>
                <p>al fine di una corretta individuazione della propria posizione in graduatoria,
                    le indichiamo di seguito il codice identificativo associato alla sua candidatura presentata
                    all’ {university} per l’ {title}</p>
                <p>Codice identificativo: {matricolaBando}</p>
                <h6><a target="blank" href={evaluationBando['url']}>Graduatoria.pdf</a></h6>
            </MSection>

        </>)
    }
}

export default withRouter(enrollmentResult);