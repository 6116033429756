import React, { Component } from "react";
import { BoxInfo, MnemoLoading, MSection } from "components/misc";
import { Button, Container, Row, Col } from "design-react-kit";
import { MSelect } from "components/forms";
import { enrollmentService, networkErrorHelper } from "libs";
import dayjs from "dayjs";

export class AnalysisSummaryReport extends Component {
  state = {
    loading: false,
    currentCompany: "",
    currentList: [],
    uniqueRegions: [],
  };

  searchField = [
    {
      field: "company",
      name: "company",
      label: "Ente",
      payload: {
        options: [
          { label: "filtro sull'ente", value: "" },
          { label: "Unicamillus", value: "unicamillus" },
          { label: "IUL", value: "iul" },
        ],
      },
      component: MSelect,
      onChange: (_, value) => this.onChangeCompany(_, value),
    },
  ];

  //--- ---
  loadRemote = async () => {
    this.setState({ loading: true });
    try {
      enrollmentService
        .getAnalysisSummaryReport(this.state.currentCompany)
        .then(({ data }) => {
          const uniqueRegions = [
            ...new Set(data.payload.currentList.map((item) => item.region)),
          ];

          this.setState({
            loading: false,
            currentList: data.payload.currentList,
            uniqueRegions: uniqueRegions,
          });
        })
        .catch((error) => {
          console.log(error);
          if (!networkErrorHelper.is404(error)) {
            networkErrorHelper.notify(error);
          }
        });

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      networkErrorHelper(error);
    }
  };

  onChangeCompany = (_, value) => {
    this.setState({ currentCompany: value });
  };

  onSearchForm = () => {
    this.loadRemote();
  };

  onExport = () => {
    //--- prepare header ---
    let str = "";

    for (let i = 0; i < this.state.uniqueRegions.length; i++) {
      //--- make region tabele block ---
      const region = this.state.uniqueRegions[i];
      str += "Regione: " + region.toUpperCase() + "\r\n";
      str +=
        "Classe;Denominazione Classe;Totale posti;Posti 30CFU;Posti 60CFU;Posti Riserva;Candidati 30CFU;Candidati 60CFU;Graduatoria;\r\n";

      const rows = this.state.currentList.filter((item) => {
        return item.region === region;
      });

      //--- make rows ---
      const columnOrder = [
        "competitionClass",
        "title",
        "sitTotal",
        "sit30",
        "sit60",
        "sitRes",
        "attendees30cfu",
        "attendees60cfu",
        "url",
      ];

      for (let i = 0; i < rows.length; i++) {
        let line = "";

        for (const col of columnOrder) {
          if (line !== "") line += ";";
          line += rows[i][col] !== undefined ? rows[i][col] : "";
        }
        str += line + "\r\n";
      }

      str += "\r\n\r\n\r\n";
    } //for

    //--- start download ---
    let downloadLink = document.createElement("a");
    downloadLink.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(str);
    downloadLink.download =
      "Report Sintetico_" +
      this.state.currentCompany +
      "_" +
      dayjs().format("DD_MM_YYYY_H_m_s") +
      ".csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //--- ---
  render() {
    const { loading, currentList, uniqueRegions } = this.state;

    return (
      <>
        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <Container className="m-4">
            <h1>Report Sintetico</h1>
            <MSection>
              <Row>
                <Col md={3}>
                  <MSelect {...this.searchField[0]} />
                </Col>
                <Col md={4}>
                  <Button
                    color="primary bg-dark mx-1 inline-block"
                    onClick={() => {
                      this.onSearchForm();
                    }}
                  >
                    Cerca
                  </Button>
                  {uniqueRegions.length > 0 && (
                    <Button
                      color="primary bg-dark mx-1 inline-block"
                      onClick={() => {
                        this.onExport();
                      }}
                    >
                      Esporta
                    </Button>
                  )}
                </Col>
              </Row>

              <hr />
            </MSection>
            <MSection>
              {uniqueRegions.length === 0 ? (
                <BoxInfo>Non ci sono dati da mostrare in database.</BoxInfo>
              ) : (
                <>
                  <Row>
                    {uniqueRegions.map((region, index) => {
                      return (
                        <table
                          key={index}
                          className="MTable table  table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th colSpan={9}>Regione {region}</th>
                            </tr>
                            <tr>
                              <td className="text-center bold">Classe</td>
                              <td className="text-center bold">
                                Denominazione Class{" "}
                              </td>
                              <td className="text-center bold">Totale posti</td>
                              <td className="text-center bold">Posti 30CFU</td>
                              <td className="text-center bold">Posti 60CFU</td>
                              <td className="text-center bold">
                                Posti Riserva
                              </td>
                              <td className="text-center bold">
                                Candidati 30CFU
                              </td>
                              <td className="text-center bold">
                                Candidati 60CFU
                              </td>
                              <td className="text-center bold">
                                Apri graduatoria
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {currentList
                              .filter((item) => {
                                return item.region === region;
                              })
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.competitionClass}</td>
                                    <td>{item.title}</td>
                                    <td className="text-center">{item.sitTotal}</td>
                                    <td className="text-center">
                                      {item.sit30}
                                    </td>
                                    <td className="text-center">
                                      {item.sit60}
                                    </td>
                                    <td className="text-center">
                                      {item.sitRes}
                                    </td>
                                    <td className="text-center">
                                      {item.attendees30cfu}
                                    </td>
                                    <td className="text-center">
                                      {item.attendees60cfu}
                                    </td>
                                    <td className="text-center">
                                      <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.url}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      );
                    })}
                  </Row>
                </>
              )}
            </MSection>
          </Container>
        )}
      </>
    );
  }
}
